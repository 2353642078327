import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MenuList } from '../MenuList';

const MainCourseList = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {
          fields: { sourceInstanceName: { eq: "menuitems" } }
          frontmatter: { draft: { eq: false }, category: { eq: "Main Course" } }
        }
        sort: { order: ASC, fields: frontmatter___order }
      ) {
        nodes {
          id
          frontmatter {
            path
            title
            price
            featuredImage {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <div>
      <MenuList title="Main Course" data={data.allMarkdownRemark.nodes} />
    </div>
  );
};

export default MainCourseList;
