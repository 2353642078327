import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { PriceTag } from '../PriceTag';
import { styleVariables } from '../../styles/Variables';
dayjs.extend(advancedFormat);

const StyledMenuFoodItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 600px) {
    flex-flow: row;
  }
`;

const StyledThumbnailImage = styled(Img)`
  height: 80px;
  background-color: #ccc;
  border-radius: ${styleVariables.borderRadius};
  margin-right: 20px;
  flex: 0 0 80px;
  border: solid 1px #ccc;

  @media (max-width: 600px) {
    height: 80px;
    margin-right: 20px;
    flex: 0 0 80px;
  }
`;

const StyledContent = styled.div`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
`;
const StyledTitle = styled.div`
  margin-top: 0;
  font-size: 1em;
  margin-bottom: 10px;
`;

interface Props {
  node: any;
}

const MenuFoodItem = ({ node }: Props) => {
  const {
    frontmatter: {
      title,
      price,
      featuredImage: {
        childImageSharp: { fluid },
      },
    },
  } = node;
  return (
    <StyledMenuFoodItem>
      <StyledThumbnailImage fluid={fluid} />
      <StyledContent>
        <div>
          <StyledTitle title={title}>
            {title.length > 30 ? title.substring(0, 30).trim() + '...' : title}
          </StyledTitle>
          <PriceTag data={node}>{price}</PriceTag>
        </div>
      </StyledContent>
    </StyledMenuFoodItem>
  );
};

export default MenuFoodItem;
