import React, { useEffect, useContext } from 'react';
import SEO from '../components/seo';
import { Layout } from '../components/Layout';
import { FoodSpecials } from '../components/FoodSpecials';
import { HTag } from '../components/HTag';
import styled from 'styled-components';
import { LatestSection } from '../components/LatestSection';
import { AppetizersList } from '../components/AppetizersList';
import { MainCourseList } from '../components/MainCourseList';
import { DessertList } from '../components/DessertList';
import { SiteContext } from '../state';

const StyledMenuGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 50px;
  @media (max-width: 768px) {
    grid-gap: 50px;
  }
  @media (max-width: 600px) {
    grid-gap: 50px;
    grid-template-columns: 1fr;
  }
`;
const MenuPage = () => {
  const { dispatch } = useContext(SiteContext);
  useEffect(() => {
    dispatch({ type: 'SET_ACTIVE_PAGE', payload: 'menu' });
  }, []);
  return (
    <>
      <Layout hasHero={false}>
        <SEO title="Menu" />
        <HTag pageHeader>Menu</HTag>
        <FoodSpecials onHomePage={false} />
        <StyledMenuGrid>
          <AppetizersList />
          <MainCourseList />
          <DessertList />
        </StyledMenuGrid>
        <LatestSection />
      </Layout>
    </>
  );
};

export default MenuPage;
