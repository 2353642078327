import React from 'react';
import { HTag } from '../HTag';
import { MenuFoodItem } from '../MenuFoodItem';
import styled from 'styled-components';

const StyledHTag = styled(HTag)`
  margin-top: 0;
`;

const StyledMenuList = styled.div``;
interface Props {
  title: string;
  data: any;
}

const MenuList = ({ title, data }: Props) => {
  return (
    <StyledMenuList>
      <StyledHTag as="h3">{title}</StyledHTag>
      <>
        {data.map((node: any, index: number) => {
          return <MenuFoodItem key={node.id} node={node} />;
        })}
      </>
    </StyledMenuList>
  );
};

export default MenuList;
